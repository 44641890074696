const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const waitForTransaction = async (connection, tx) => {
	const promise = new Promise(async (res, rej) => {
		let receipt = null;
		while (receipt === null) {
			receipt = await connection.confirmTransaction(tx, "confirmed");
			await sleep(1000);
		}
		res(receipt);
	});
	return promise;
};
