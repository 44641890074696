import { Connection, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { programs } from "@metaplex/js";
import axios from "axios";
import { toast } from "react-toastify";
import nftMints from "./hashlist.json";
const {
	metadata: { Metadata },
} = programs;
export async function getTokensByOwner(owner, conn) {
	const tokens = await conn.getParsedTokenAccountsByOwner(owner, {
		programId: TOKEN_PROGRAM_ID,
	});

	// initial filter - only tokens with 0 decimals & of which 1 is present in the wallet
	return tokens.value
		.filter((t) => {
			const amount = t.account.data.parsed.info.tokenAmount;
			return amount.decimals === 0 && amount.uiAmount === 1;
		})
		.map((t) => {
			return { pubkey: t.pubkey, mint: t.account.data.parsed.info.mint };
		});
}

async function getNFTMetadata(mint, conn, pubkey) {
	//console.log(mint.toString(), nftMints.includes(mint));
	try {
		//	console.log("Pulling metadata for:", mint.toString());

		if (nftMints.includes(mint.toString())) {
			const metadataPDA = await Metadata.getPDA(mint);
			const onchainMetadata = (await Metadata.load(conn, metadataPDA)).data;
			const externalMetadata = (await axios.get(onchainMetadata.data.uri)).data;

			return {
				pubkey: pubkey ? new PublicKey(pubkey) : undefined,
				mint: new PublicKey(mint),
				onchainMetadata,
				externalMetadata,
			};
		}

		/* 	if (onchainMetadata.data.creators[0].address === process.env.REACT_APP_WHITELIST_CREATOR) {
			const externalMetadata = (await axios.get(onchainMetadata.data.uri)).data;

			return {
				pubkey: pubkey ? new PublicKey(pubkey) : undefined,
				mint: new PublicKey(mint),
				onchainMetadata,
				externalMetadata,
			};
		} */
	} catch (e) {
		console.log(`Metadata not exist for Token:${mint}`);
	}
}

export async function getNFTMetadataForMany(tokens, conn) {
	const promises = [];
	tokens.forEach((t) => promises.push(getNFTMetadata(t.mint, conn, t.pubkey)));
	const pre_process = await toast.promise(Promise.all(promises), {
		pending: "Loading your NFTs",
		success: "NFT Loaded,please wait for image load",
		error: "Error loading your NFTs",
	});
	const nfts = pre_process.filter((n) => !!n);

	console.log(`found ${nfts.length} metadatas`);

	return nfts;
}

export async function getNFTsByOwner(owner, conn) {
	const tokens = await toast.promise(getTokensByOwner(owner, conn), {
		pending: "Loading your NFTs",
		success: "Metadata Loaded",
		error: "Error loading your NFTs",
	});
	console.log(`found ${tokens.length} tokens`);

	return await getNFTMetadataForMany(tokens, conn);
}
