import logo from "./logo.svg";
import "./App.css";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
	LedgerWalletAdapter,
	PhantomWalletAdapter,
	SlopeWalletAdapter,
	SolflareWalletAdapter,
	SolletExtensionWalletAdapter,
	SolletWalletAdapter,
	TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { ToastContainer } from "react-toastify";
import { WalletModalProvider, WalletDisconnectButton, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { useMemo } from "react";
import ProgramInteract from "./pages/ProgramInteract";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./pages/Admin";

import "react-toastify/dist/ReactToastify.css";
import("@solana/wallet-adapter-react-ui/styles.css");

function App() {
	//const network = WalletAdapterNetwork.Devnet;
	//	const network = process.env.REACT_APP_RPC_ENDPOINT;
	const network = "https://weathered-winter-gas.solana-mainnet.quiknode.pro/ef256cf358e09d4609f2c7f83110c2d23dd82712/";
	// You can also provide a custom RPC endpoint.
	/* 	const endpoint = useMemo(
		() => "https://wispy-proud-pond.solana-devnet.quiknode.pro/078789ebda505cce1f27459e944f9cbc4a391536/",
		[]
	); */

	// @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
	// Only the wallets you configure here will be compiled into your application, and only the dependencies
	// of wallets that your users connect to will be loaded.
	const wallets = useMemo(
		() => [
			new PhantomWalletAdapter(),
			new SlopeWalletAdapter(),
			new SolflareWalletAdapter({ network }),
			new TorusWalletAdapter(),
			new LedgerWalletAdapter(),
			new SolletWalletAdapter({ network }),
			new SolletExtensionWalletAdapter({ network }),
		],
		[network]
	);
	return (
		<>
			<ConnectionProvider endpoint={network}>
				<WalletProvider wallets={wallets} autoConnect>
					<WalletModalProvider>
						<BrowserRouter>
							<div style={{ position: "fixed", right: 0, zIndex: 1001 }}>
								<WalletMultiButton />
							</div>
							<Routes>
								<Route path="/" element={<ProgramInteract />}></Route>
								<Route path="/admin" element={<Admin />} />
							</Routes>
							<ToastContainer toastStyle={{ background: "#1a0033", color: "white" }} />
						</BrowserRouter>
					</WalletModalProvider>
				</WalletProvider>
			</ConnectionProvider>
		</>
	);
}

export default App;
