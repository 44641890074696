import React, { useEffect } from "react";
import * as anchor from "@project-serum/anchor";
import { programs } from "@metaplex/js";
import idl from "../../idl/samo_burn.json";
import { getNFTsByOwner } from "../../lib/NFTget";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getAssociatedTokenAddress, ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { toast } from "react-toastify";
import { waitForTransaction } from "../../lib/Transaction";

export default function ProgramInteract() {
	const { connection } = useConnection();

	const wallet = useWallet();
	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	const programId = new anchor.web3.PublicKey(process.env.REACT_APP_BURN_PROGRAM_ID);
	const REWARD_MINT = new anchor.web3.PublicKey(process.env.REACT_APP_REWARD_TOKEN_MINT);
	const metaplex_program_Id = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s";
	const vaultManager = new anchor.web3.PublicKey(process.env.REACT_APP_VAULT_CREATOR_ADDRESS);
	const provider = new anchor.Provider(connection, wallet, "processed");
	const program = new anchor.Program(idl, programId, provider);

	const [nfts, setNfts] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	useEffect(() => {
		if (!provider.wallet.publicKey) return;
		getNFTsByOwner(provider.wallet.publicKey, provider.connection).then((tokens) => {
			setNfts(tokens);
			setLoading(false);
			if (process.env.NODE_ENV === "development") console.log(tokens);
		});
	}, [provider.wallet.publicKey]);

	const burn = async (NFTAddress) => {
		const [vault_pda, _bump_vault] = await anchor.web3.PublicKey.findProgramAddress(
			[Buffer.from("vault"), vaultManager.toBuffer()],
			program.programId
		);

		const nftMint = new anchor.web3.PublicKey(NFTAddress);
		const metaplex_pubKey = new anchor.web3.PublicKey(metaplex_program_Id);
		const [metadata_pda, _bump_meta] = await anchor.web3.PublicKey.findProgramAddress(
			[Buffer.from("metadata"), metaplex_pubKey.toBuffer(), nftMint.toBuffer()],
			metaplex_pubKey
		);
		const nftTokenAccount = await getAssociatedTokenAddress(
			nftMint,
			provider.wallet.publicKey,
			false,
			TOKEN_PROGRAM_ID,
			ASSOCIATED_TOKEN_PROGRAM_ID
		);
		//The USER Reward token Account
		/* 	const [rewardTokenAccount, rewardTokenAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
			[vault_pda.toBuffer(), provider.wallet.publicKey.toBuffer()],
			program.programId
		); */

		const rewardTokenAccount = await getAssociatedTokenAddress(
			REWARD_MINT,
			provider.wallet.publicKey,
			false,
			TOKEN_PROGRAM_ID,
			ASSOCIATED_TOKEN_PROGRAM_ID
		);

		//Vault Reward token Account
		const [vaultRewardPDA, vaultRewardBump] = await anchor.web3.PublicKey.findProgramAddress(
			[vault_pda.toBuffer()],
			program.programId
		);

		const tx = await program.rpc.burnNft({
			accounts: {
				vault: vault_pda,
				nftMint: nftMint,
				nftMetadataAccount: metadata_pda,
				burner: provider.wallet.publicKey,
				nftTokenAccount: nftTokenAccount,
				//associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
				tokenProgram: TOKEN_PROGRAM_ID,
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				//	vaultTokenAccount,
				//REWARD
				vaultRewardTokenAccount: vaultRewardPDA,
				rewardMint: REWARD_MINT,
				rewardTokenAccount: rewardTokenAccount,
				associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			},
			signers: [],
		});

		await toast.promise(waitForTransaction(connection, tx), {
			pending: "Saying goodbye to your baby Samo",
			success: "Your baby Samo transformed into some coins and stored in your wallet",
			error: "Something went wrong,try again later",
		});
		window.location.reload();
	};
	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<h2 className="text-center">Baby</h2>

					<h2 className="text-center">Samo</h2>
				</div>

				<h3 className="text-center">NFT Burn</h3>
				<div style={{ alignSelf: "center" }}>
					<div className="nes-balloon from-right is-dark">
						<h3>Click On Image To Burn</h3>
					</div>
					<img src="/img/babysamo.png" alt="babysamo" height={"150px"} style={{ marginTop: "50px" }} />
				</div>
				<p style={{ fontSize: "1.5vmin" }}>Program Address:{program.programId.toBase58()}</p>
				<div className="nes-container is-dark grid ">
					{nfts.map((nft, idx) => {
						return (
							<div key={idx} style={{ display: "flex", flexDirection: "column" }}>
								{loading && <div>LOADING YOUR NFT</div>}

								<img
									className="zoom nes-pointer"
									width="300"
									src={nft.externalMetadata.image}
									style={{ borderRadius: "10px" }}
									alt="NFTIMAGE"
									onClick={() => burn(nft.mint.toString())}
								></img>
								<p className="text-center">#{nft.externalMetadata.edition}</p>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}
