import React, { useEffect, useRef, useState } from "react";
import * as anchor from "@project-serum/anchor";
import { programs } from "@metaplex/js";
import idl from "../../idl/samo_burn.json";
import { getNFTsByOwner } from "../../lib/NFTget";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getAssociatedTokenAddress, ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, getMint } from "@solana/spl-token";
import { toast } from "react-toastify";
import { waitForTransaction } from "../../lib/Transaction";
export default function Admin() {
	const { connection } = useConnection();
	const [pdaVaultAddress, setPdaVaultAddress] = useState();
	const [vaultTokenAddress, setVaultTokenAddress] = useState();
	const [vaultBalance, setVaultBalance] = useState();

	const [haveVault, setHaveVault] = useState(false);
	const wallet = useWallet();

	const programId = new anchor.web3.PublicKey(process.env.REACT_APP_BURN_PROGRAM_ID);
	const REWARD_MINT = new anchor.web3.PublicKey(process.env.REACT_APP_REWARD_TOKEN_MINT);
	const metaplex_program_Id = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s";
	const vaultManager = new anchor.web3.PublicKey(process.env.REACT_APP_VAULT_CREATOR_ADDRESS);
	const provider = new anchor.Provider(connection, wallet, "processed");
	const program = new anchor.Program(idl, programId, provider);

	const whitelistRef = useRef();
	const rewardAmountRef = useRef();
	const transferAmountRef = useRef();
	const withdrawAmountRef = useRef();
	useEffect(() => {
		async function getPda() {
			if (!wallet.publicKey) return;

			const [vault_pda, _bump_vault] = await anchor.web3.PublicKey.findProgramAddress(
				[Buffer.from("vault"), vaultManager.toBuffer()],
				program.programId
			);
			try {
				const mintInfo = await getMint(connection, REWARD_MINT);
				const config = await program.account.vault.fetch(vault_pda);
				rewardAmountRef.current.value = config.rewardAmount.toNumber() / anchor.web3.LAMPORTS_PER_SOL;
				whitelistRef.current.value = config.whitelistCreator.toBase58();
				let vb = await connection.getTokenAccountBalance(config.rewardTokenAccount);
				setVaultBalance(vb.value.amount / anchor.web3.LAMPORTS_PER_SOL);
				setVaultTokenAddress(config.rewardTokenAccount.toBase58());
				setPdaVaultAddress(vault_pda.toBase58());
			} catch (e) {
				console.error(e.message);
			}
		}
		getPda();
	}, [wallet]);
	const createVault = async () => {
		const [vault_pda, _bump] = await anchor.web3.PublicKey.findProgramAddress(
			[Buffer.from("vault"), vaultManager.toBuffer()],
			program.programId
		);

		if (process.env.NODE_ENV === "development") console.log(vault_pda.toBase58());
		const tx = await toast.promise(
			program.methods
				.initVault()
				.accounts({
					vault: vault_pda,
					payer: provider.wallet.publicKey,
					systemProgram: anchor.web3.SystemProgram.programId,
				})
				.rpc(),
			{ pending: "Loading Transaction", success: "Transaction Successful", error: "Transaction Failed" }
		);

		const promise = waitForTransaction(connection, tx);
		toast.promise(promise, {
			pending: "Creating Vault",
			error: "Error creating Vault",
			success: "Success creating vault",
		});
		setPdaVaultAddress(vault_pda.toBase58());
		window.location.reload();
	};
	const configVault = async () => {
		const [vault_pda, _bump] = await anchor.web3.PublicKey.findProgramAddress(
			[Buffer.from("vault"), vaultManager.toBuffer()],
			program.programId
		);
		const tx = await program.methods
			.configVault(
				new anchor.web3.PublicKey(whitelistRef.current.value),
				new anchor.BN(parseInt(rewardAmountRef.current.value * anchor.web3.LAMPORTS_PER_SOL))
			)
			.accounts({
				vault: vault_pda,
				manager: provider.wallet.publicKey,
				systemProgram: anchor.web3.SystemProgram.programId,
			})
			.rpc();
		const promise = waitForTransaction(connection, tx);
		const result = await toast.promise(promise, {
			pending: "Processing ",
			error: "Error",
			success: "Success",
		});
		if (process.env.NODE_ENV === "development") console.log(result);
		window.location.reload();
	};
	const transferToken = async () => {
		const [vault_pda, _bump_vault] = await anchor.web3.PublicKey.findProgramAddress(
			[Buffer.from("vault"), vaultManager.toBuffer()],
			program.programId
		);
		const [vaultRewardPDA, vaultRewardBump] = await anchor.web3.PublicKey.findProgramAddress(
			[vault_pda.toBuffer()],
			program.programId
		);
		const senderTokenAccount = await getAssociatedTokenAddress(
			REWARD_MINT,
			provider.wallet.publicKey,
			false,
			TOKEN_PROGRAM_ID,
			ASSOCIATED_TOKEN_PROGRAM_ID
		);
		if (process.env.NODE_ENV === "development") console.log(senderTokenAccount.toBase58());
		const amount = new anchor.BN((transferAmountRef.current.value * anchor.web3.LAMPORTS_PER_SOL).toString());

		const tx = await program.methods
			.transferTokenToVault(vaultRewardBump, amount)
			.accounts({
				vault: vault_pda,
				sender: provider.wallet.publicKey,
				senderTokenAccount: senderTokenAccount,
				rewardMint: REWARD_MINT,
				vaultRewardTokenAccount: vaultRewardPDA,
				tokenProgram: TOKEN_PROGRAM_ID,
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
			})
			.rpc();
		await toast.promise(waitForTransaction(connection, tx), {
			pending: "Processing ",
			error: "Error",
			success: "Success",
		});
		window.location.reload();
	};
	const withdrawTokenFromVault = async () => {
		const [vault_pda, _bump_vault] = await anchor.web3.PublicKey.findProgramAddress(
			[Buffer.from("vault"), vaultManager.toBuffer()],
			program.programId
		);
		const [vaultRewardPDA, vaultRewardBump] = await anchor.web3.PublicKey.findProgramAddress(
			[vault_pda.toBuffer()],
			program.programId
		);
		const ownerTokenAccount = await getAssociatedTokenAddress(
			REWARD_MINT,
			provider.wallet.publicKey,
			false,
			TOKEN_PROGRAM_ID,
			ASSOCIATED_TOKEN_PROGRAM_ID
		);
		const amount = new anchor.BN((withdrawAmountRef.current.value * anchor.web3.LAMPORTS_PER_SOL).toString());
		const tx = await program.methods
			.withdrawTokenFromVault(amount)
			.accounts({
				vault: vault_pda,
				manager: provider.wallet.publicKey,
				vaultRewardTokenAccount: vaultRewardPDA,
				rewardMint: REWARD_MINT,
				rewardTokenAccount: ownerTokenAccount,
				tokenProgram: TOKEN_PROGRAM_ID,
			})
			.rpc();
		await toast.promise(waitForTransaction(connection, tx), {
			pending: "Processing ",
			error: "Error",
			success: "Success",
		});
		window.location.reload();
	};
	const fetchConfig = async () => {
		const [vault_pda, _bump_vault] = await anchor.web3.PublicKey.findProgramAddress(
			[Buffer.from("vault"), vaultManager.toBuffer()],
			program.programId
		);
		const config = await program.account.vault.fetch(vault_pda);
		console.log(config);
	};
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				padding: "100px",
				gap: "33px",
			}}
		>
			<button onClick={createVault}>Create Vault</button>
			<p>Your Vault Address:{pdaVaultAddress ? pdaVaultAddress : "You have no vault yet"}</p>

			<div className="nes-container with-title is-dark is-centered">
				<p className="title">Config Vault</p>
				<div className="nes-field">
					<label htmlFor="whitelist_field">NFT CandyMachine ID(First Creator)</label>
					<input ref={whitelistRef} type="text" id="whitelist_field" className="nes-input" />
				</div>
				<div className="nes-field">
					<label htmlFor="name_field">Reward Amount</label>
					<input ref={rewardAmountRef} type="number" id="name_field" className="nes-input" />
				</div>
				<button onClick={configVault}>Config Vault</button>
			</div>
			<div className="nes-container with-title is-dark is-centered">
				<p className="title">Transfer Token to vault</p>
				<p>Vault Token Address:{vaultTokenAddress}</p>
				<p>Vault Balance : {vaultBalance}</p>
				<div className="nes-field">
					<label htmlFor="transferAmount">Transfer Amount</label>
					<input ref={transferAmountRef} type="number" id="transferAmount" className="nes-input" />
				</div>

				<button onClick={transferToken}>Transfer!</button>
			</div>
			<div className="nes-container with-title is-dark is-centered">
				<p className="title">Withdraw Token from vault</p>
				<p>Vault Balance : {vaultBalance}</p>
				<div className="nes-field">
					<label htmlFor="withdrawAmount">Transfer Amount</label>
					<input ref={withdrawAmountRef} type="number" id="withdrawAmount" className="nes-input" />
				</div>

				<button onClick={withdrawTokenFromVault}>Withdrawn!</button>
			</div>

			<button onClick={fetchConfig}>fetch config(Console Log)</button>
		</div>
	);
}
